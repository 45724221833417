import { isNewTheme } from "core/model/utils/featureFlags";
import { IconType, InfoIcon } from "ds/icons";
import {
  ACCENT_DARK_COLOR,
  ICON_GREY,
  newThemeColors,
} from "ds_legacy/materials/colors";
import { sizing } from "ds_legacy/materials/metrics";
import { Caption, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import React, { ReactNode } from "react";
import styled from "styled-components";

const ContentWrapper = styled.div<{ centered?: boolean; margin?: string }>`
  display: flex;
  align-items: ${(props) => (props.centered ? "center" : "flex-start")};
  ${(props) =>
    props.margin ? `margin: ${props.margin}` : `margin-bottom: ${sizing(1)};`}
`;

export type InfoCaptionProps = {
  captionStyle?: React.CSSProperties;
  centered?: boolean;
  children?: ReactNode;
  color?: string;
  greyed?: boolean;
  icon?: IconType;
  margin?: string;
  text?: string;
  wrapperStyle?: React.CSSProperties;
};

const InfoCaption = ({
  captionStyle,
  centered,
  children,
  color,
  greyed,
  icon,
  margin,
  text,
  wrapperStyle,
}: InfoCaptionProps) => {
  const Icon = icon || InfoIcon;
  return (
    <ContentWrapper margin={margin} centered={centered} style={wrapperStyle}>
      <div>
        <Icon
          size={FONT_SIZE_16}
          style={{
            fontSize: FONT_SIZE_16,
            color: color
              ? color
              : greyed
              ? ICON_GREY
              : isNewTheme
              ? newThemeColors.primary.main
              : ACCENT_DARK_COLOR,
          }}
        />
      </div>
      <Caption
        as="p"
        style={{ whiteSpace: "pre-line", ...captionStyle }}
        data-testid="info-caption"
      >
        {children != null ? children : text}
      </Caption>
    </ContentWrapper>
  );
};
export default InfoCaption;

import {
  Theme as MuiTheme,
  PaletteColorOptions,
  ThemeOptions,
  createTheme,
} from "@mui/material";
import { PaletteOptions, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";
import {
  APP_ACP,
  APP_B2C,
  APP_CAREPROVIDER,
  APP_PROVIDER_SEARCH,
  RESPONSIVE_BREAKPOINT_MOBILE,
  RESPONSIVE_BREAKPOINT_TABLET,
  RESPONSIVE_BREAKPOINT_XL_DESKTOP,
} from "core/consts";
import {
  BLACK,
  INPUT_TEXT_SELECTION,
  KEYBOARD_FOCUS_OUTLINE,
  WHITE,
  hexToHSL,
} from "ds_legacy/materials/colors";
import { FONT_FAMILY, FONT_SIZE_14 } from "ds_legacy/materials/typography";
import { fromJS } from "immutable";
import { border, dp, important, padding } from "./metrics";
import { paletteACP } from "./palettes/acp";
import { paletteB2C } from "./palettes/b2c";
import { paletteBCP } from "./palettes/bcp";
import { paletteProvider } from "./palettes/careprovider";
import { paletteSender } from "./palettes/sender";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles/createPalette" {}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    desktop: true;
    md: false;
    mobile: true;
    tablet: true;
    xl: true;
  }
}

const whitePalette: PaletteColorOptions = {
  50: WHITE,
  100: WHITE,
  200: WHITE,
  300: WHITE,
  400: WHITE,
  500: WHITE,
  600: WHITE,
  700: WHITE,
  800: WHITE,
  900: WHITE,
  A100: WHITE,
  A200: WHITE,
  A400: WHITE,
  A700: WHITE,
};

export const getFocusVisibleOutline = (): CSSProperties => ({
  boxSizing: "border-box",
  "&.Mui-focusVisible": {
    outline: `${dp(2)} solid ${KEYBOARD_FOCUS_OUTLINE}`,
  },
});

const getDarkTheme = (theme: MuiTheme): MuiTheme => {
  const palette: PaletteOptions = {
    primary: whitePalette,
    mode: "dark",
  };

  return fromJS(theme).mergeDeep({ palette }).toJS();
};

const getBaseTheme = (app: number) => {
  const base: ThemeOptions = {
    typography: {
      fontFamily: FONT_FAMILY,
    },
    breakpoints: {
      values: {
        xs: 0,
        mobile: 0,
        sm: RESPONSIVE_BREAKPOINT_MOBILE + 1, // 600 +1 to match useMedia
        tablet: RESPONSIVE_BREAKPOINT_MOBILE + 1, // 600 +1 to match useMedia
        lg: RESPONSIVE_BREAKPOINT_TABLET + 1, // 1024 +1 to match useMedia
        desktop: RESPONSIVE_BREAKPOINT_TABLET + 1, // 1024 +1 to match useMedia
        xl: RESPONSIVE_BREAKPOINT_XL_DESKTOP, // 1536
      },
    },
    // Render all modal-like components contained inside the mount point instead of body
    // in order to prevent screen reader issues when the modals hide the aria-live announcer
    components: {
      MuiDialog: {
        defaultProps: {
          container: document.getElementById("app"),
        },
      },
      MuiModal: {
        defaultProps: {
          container: document.getElementById("app"),
        },
      },
      MuiPopover: {
        defaultProps: {
          container: document.getElementById("app"),
        },
      },
    },
  };
  switch (app) {
    case APP_CAREPROVIDER:
      return createTheme({ ...base, palette: paletteProvider });
    case APP_ACP:
      return createTheme({ ...base, palette: paletteACP });
    case APP_B2C:
      return createTheme({ ...base, palette: paletteB2C });
    case APP_PROVIDER_SEARCH:
      return createTheme({ ...base, palette: paletteBCP });
    default:
      return createTheme({ ...base, palette: paletteSender });
  }
};

const getLightTheme = (app: number) => {
  const theme = getBaseTheme(app);

  const customizations: ThemeOptions = {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            padding: padding(0.25),
            ...getFocusVisibleOutline(),
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 4,
            outlineOffset: important(dp(-4)),
            ...getFocusVisibleOutline(),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::selection": {
              color: BLACK,
              background: INPUT_TEXT_SELECTION,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor:
              app === APP_PROVIDER_SEARCH
                ? theme.palette.neutrals.dark_grey
                : undefined,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: padding(0.25),
            ...getFocusVisibleOutline(),
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            padding: padding(0.25),
            ...getFocusVisibleOutline(),
            ":hover": {
              boxShadow: `0px 0px 0px ${dp(7)} ${hexToHSL(
                theme.palette.primary.main,
                40,
              )}, 0px 0px 0px ${dp(8)} ${hexToHSL(theme.palette.primary.main)}`,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: FONT_SIZE_14,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            transition: "none",
            border: border({ color: "transparent", important: true }),
            borderRadius: 999999,
            ":hover": {
              border: border({
                color: theme.palette.primary.main,
                important: true,
              }),
              background: theme.palette.grey["200"],
            },
            ...getFocusVisibleOutline(),
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            outlineOffset: dp(-2),
            ...getFocusVisibleOutline(),
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            outlineOffset: dp(-2),
            ...getFocusVisibleOutline(),
          },
        },
      },
    },
  };

  return fromJS(theme).mergeDeep(customizations).toJS();
};

export function getTheme(app: number, dark?: boolean): MuiTheme {
  const lightTheme = getLightTheme(app);
  return dark ? getDarkTheme(lightTheme) : lightTheme;
}

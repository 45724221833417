import { useSafeState } from "core/hooks";
import { getLocale } from "core/model/utils/dates";
import { de } from "date-fns/locale";
import { useEffect } from "react";
import { useLocale } from "translations";

export const useDateLocale = () => {
  const [currentLocale, setCurrentLocale] = useSafeState(de);
  const locale = useLocale();

  const setDateLocale = () => {
    const selectedLocale = getLocale(locale);
    setCurrentLocale(selectedLocale);
  };

  useEffect(() => {
    if (currentLocale.code?.replace("-GB", "") !== locale) {
      setDateLocale();
    }
  }, [locale]);

  return { currentLocale };
};

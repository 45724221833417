import { FormHelperText } from "@mui/material";
import Checkbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CSSProperties } from "@mui/styles";
import { CHECKBOX_SIZE, CHECKBOX_SPACE, POSITIVE_DIFF } from "core/consts";
import { PatientDiffType, Validation, ValueOf } from "core/types";
import Tooltip from "ds_legacy/components/Tooltip";
import {
  ERROR_COLOR,
  NEGATIVE_DIFF_STYLE,
  POSITIVE_DIFF_STYLE,
  TEXT_DARK_PRIMARY,
  WHITE,
} from "ds_legacy/materials/colors";
import { dp, padding, space } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_10,
  FONT_SIZE_13,
  FONT_SIZE_14,
  FONT_SIZE_15,
  FONT_SIZE_16,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { ChangeEvent, InputHTMLAttributes, ReactNode } from "react";
import { FormElementRenderProps, isValid } from "react-forms-state";
import { useTranslations } from "translations";
import { v4 as uuidv4 } from "uuid";
import { getHelperText } from "../Validation";

export type CheckboxProps = {
  checkboxSize?: ValueOf<typeof CHECKBOX_SIZE>;
  checkboxSpace?: ValueOf<typeof CHECKBOX_SPACE>;
  checked?: MuiCheckboxProps["checked"];
  customValue?: string | null;
  diff?: PatientDiffType;
  disableChange?: boolean;
  disabled?: MuiCheckboxProps["disabled"];
  elementName?: string;
  fieldValidation?: boolean;
  flatModel?: boolean;
  hasBooleanValue?: boolean;
  hide?: boolean;
  id?: MuiCheckboxProps["id"];
  important?: boolean;
  indeterminate?: MuiCheckboxProps["indeterminate"];
  inputProps?: MuiCheckboxProps["inputProps"];
  isGroup?: boolean;
  label: ReactNode | number | string;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  labelStyleOverride?: CSSProperties;
  margin?: string;
  maxWidth?: string;
  onBeforeChange?: (value: any) => any;
  onChange?:
    | ((value: any, statePath?: string) => void)
    | ((value: any) => void);
  required?: MuiCheckboxProps["required"];
  sideMutation?: (
    value: boolean,
    mutateElement: (v: any, name: string) => void,
  ) => void;
  tooltip?: string;
  validation?: Validation;
  value?: MuiCheckboxProps["value"];
  width?: string;
};

export function isChecked(value: ToType, hasBooleanValue?: boolean) {
  if (hasBooleanValue) return Boolean(value);
  return value === "" ? true : Boolean(value);
}

function getFormControlLabelStyle({
  checkboxSpace,
  diff,
  important,
  isGroup,
  margin,
  maxWidth,
  validation,
  width,
}: Pick<
  CheckboxProps,
  | "checkboxSpace"
  | "isGroup"
  | "diff"
  | "important"
  | "margin"
  | "maxWidth"
  | "validation"
  | "width"
>): CSSProperties {
  let labelStyles: CSSProperties = {};

  if (important) {
    labelStyles.fontSize = FONT_SIZE_15;
  } else if (diff) {
    labelStyles.fontSize = FONT_SIZE_16;
  } else {
    labelStyles.fontSize =
      checkboxSpace === CHECKBOX_SPACE.SMALL ? FONT_SIZE_13 : FONT_SIZE_14;
  }

  if (diff) {
    if (diff === POSITIVE_DIFF) {
      labelStyles = { ...labelStyles, ...POSITIVE_DIFF_STYLE };
    } else {
      labelStyles = { ...labelStyles, ...NEGATIVE_DIFF_STYLE };
    }
  } else if (!isValid(validation)) {
    labelStyles = { ...labelStyles, color: isGroup ? undefined : ERROR_COLOR };
  } else {
    labelStyles = { ...labelStyles };
  }

  return {
    maxWidth,
    width,
    margin,
    wordBreak: "break-word",
    "@media print": {
      fontSize: FONT_SIZE_10,
    },
    "& .MuiFormControlLabel-label, & .MuiFormControlLabel-asterisk":
      labelStyles,
    "& .MuiFormControlLabel-label.Mui-disabled": {
      opacity: "1",
      color: TEXT_DARK_PRIMARY,
    },
  };
}

function getCheckboxStyle({
  checkboxSize,
  checkboxSpace,
  isGroup,
  print,
  validation,
}: {
  checkboxSize: ValueOf<typeof CHECKBOX_SIZE>;
  checkboxSpace: ValueOf<typeof CHECKBOX_SPACE>;
  isGroup?: boolean;
  print: boolean;
  validation?: Validation;
}): CSSProperties {
  return {
    height: print ? dp(22) : space(checkboxSpace),
    padding: `${padding(0, 1.5)}!important`,
    color: isGroup ? undefined : !isValid(validation) ? ERROR_COLOR : undefined,
    transform: `scale(${checkboxSize})`,
    "& .MuiSvgIcon-root": {
      background: WHITE,
    },
  };
}

export function CheckboxInputFieldPresenter({
  checkboxSpace = CHECKBOX_SPACE.MEDIUM,
  checkboxSize = CHECKBOX_SIZE.MEDIUM,
  checked,
  customValue,
  diff,
  disableChange,
  disabled,
  elementName,
  hasBooleanValue,
  hide,
  important = false,
  inputProps,
  label,
  labelPlacement,
  labelStyleOverride,
  tooltip,
  id,
  margin,
  maxWidth = "100%",
  onBeforeChange,
  onChange,
  isGroup,
  validation,
  required,
  value,
  width,
  indeterminate,
}: CheckboxProps) {
  const translations = useTranslations();
  const print = usePrint();
  const canUseCustomValue = typeof customValue !== "undefined";
  const errorTextId = `${elementName}-error-${uuidv4()}`;
  const hasError = !isValid(validation);
  if (hide) return null;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (disableChange) return;

    if (canUseCustomValue) {
      onChange?.(checked ? value : null, elementName);
      return;
    }

    if (onChange && onBeforeChange) {
      onChange(onBeforeChange(checked));
    } else if (onChange) {
      onChange(checked);
    }
  };

  return (
    <>
      <Tooltip title={tooltip}>
        <FormControlLabel
          labelPlacement={labelPlacement}
          sx={{
            ...getFormControlLabelStyle({
              maxWidth,
              width,
              margin,
              checkboxSpace,
              diff,
              isGroup,
              important,
              validation,
            }),
            ...labelStyleOverride,
          }}
          name={String(elementName)}
          control={
            <Checkbox
              color="primary"
              sx={getCheckboxStyle({
                print,
                checkboxSpace,
                isGroup,
                validation,
                checkboxSize,
              })}
              id={id}
              name={elementName}
              checked={
                canUseCustomValue ? checked : isChecked(value, hasBooleanValue)
              }
              indeterminate={indeterminate}
              onChange={onChangeHandler}
              value={canUseCustomValue ? customValue : String(elementName)}
              disabled={disabled}
              inputProps={{
                ...inputProps,
                ...({
                  "aria-invalid": hasError ? true : undefined,
                  "data-testid": `checkbox_${elementName}`,
                  "aria-describedby": `${hasError ? errorTextId : ""} ${
                    inputProps?.["aria-describedby"] ?? ""
                  }`.trim(),
                } as InputHTMLAttributes<HTMLInputElement>),
              }}
            />
          }
          label={label}
          required={required}
        />
      </Tooltip>
      {!isGroup && hasError && (
        <FormHelperText id={errorTextId} error={hasError}>
          {getHelperText({
            hasCustomValidation: true,
            translations,
            validation,
          })}
        </FormHelperText>
      )}
    </>
  );
}

const CheckboxInputField = ({
  elementName,
  fieldValidation,
  flatModel,
  sideMutation,
  ...props
}: Omit<CheckboxProps, "checked">) => (
  <FormElementRenderProps
    flatModel={flatModel}
    sideMutation={sideMutation}
    elementName={elementName}
  >
    {({ onChange, validation, value }) => {
      const canUseCustomValue = typeof props.customValue !== "undefined";
      return (
        <CheckboxInputFieldPresenter
          onChange={onChange}
          elementName={elementName}
          value={value}
          validation={fieldValidation ?? validation}
          checked={canUseCustomValue ? !!value : undefined}
          {...props}
        />
      );
    }}
  </FormElementRenderProps>
);

export default CheckboxInputField;
